import React, { Component, memo } from "react";
import { InfiniteLoader, AutoSizer, MultiGrid } from "react-virtualized";
import { connect } from "react-redux";
import * as _ from "lodash";
import NumAbbr from "number-abbreviate";
import { contextMenu } from "react-contexify";

import Symbol from "../shared/Symbol";
import LULD from "../shared/LULD";
import DiscoveryLockedFieldMark from "../shared/DiscoveryLockedFieldMark";
import Sparkline from "./Sparkline";

import { DiscoveryActions } from "../store";

import styleClasses from "./DiscoveryTable.module.scss";

import API from "../api";

import { DISCOVERY_TIMEFRAME_INDEPENDENT_DATA_FIELDS } from "./DiscoveryUtils";
import {
  DEFAULT_NEWS_CONFIG,
  SQUEEZE_SORT_LABEL,
  MONEYFLOW_CONTEXT_MENU_ID,
  isPro,
  isProNew,
  isProPlusNew,
  isActiveSubscription,
  TREND_CONTEXT_MENU_ID,
  ALERT_CONTEXT_MENU_ID,
  VOLUME_CONTEXT_MENU_ID,
  DEFAULT_DISCOVERY_COLUMNS,
  DISCOVERY_DISPLAY_PERCENT_CHANGE,
  TIMEFRAME_TRANSFORM_MAP,
  TRADE_COUNT_CONTEXT_MENU_ID,
  DEFAULT_DISCOVERY_SETTING_LIST,
  DISCOVERY_SPARKLINE_UPDATE_INTERVAL,
  DISCOVERY_DEFAULT_FILTEROUT_FADEOUT_TIME,
} from "../constants";
import {
  encodeDiscoveryFieldForTimeframe,
  getPlanSubscriptionStatus,
  isColumnAccessible,
  isRegularMarketOpen,
} from "../util";
import withScreenSizes from "../shared/Utilities/withScreenSizes";

import "react-virtualized/styles.css";
import "./DiscoveryTable.scss";

const moment = require("moment-timezone");

// let alertContextTrigger = null;

const numAbbr = new NumAbbr(["k", "m"], 2);
const numAbbrExt = new NumAbbr(["k", "m", "b"], 2);
const numAbbrExt2 = new NumAbbr(["k", "m", "b", "t"], 2);

class DiscoveryTable extends Component {
  constructor() {
    super();

    this.state = {
      scrollTop: 0,
      winResizeRerender: 0,
      sparklineUpdated: 0,
      sparklineExpiryTime: 0,
    };

    this.loadMoreSparklines = this.loadMoreSparklines.bind(this);
    this._rowClassName = this._rowClassName.bind(this);

    this.visibleSymbols = [];
    this.viewportStartIndex = 0;
    this.viewportStopIndex = 0;

    this.cellRenderer = this.cellRenderer.bind(this);
    this.dataCellRenderer = this.dataCellRenderer.bind(this);
    this.fetchSparklineData = this.fetchSparklineData.bind(this);
    this.handleWindowResize = _.debounce(this.handleWindowResizeFunc.bind(this), 100);
  }

  lastRenderedRows = { startIndex: 0, stopIndex: 0 };
  sparklineData = {};

  componentDidMount() {
    this.viewportTrackingTimerId = setInterval(() => this.onCheckViewportSymbols(), 2000);

    window.addEventListener("resize", this.handleWindowResize);

    const {
      widget,
      config: { discovery: discoveryColumns },
    } = this.props;
    this.hasSparklines =
      discoveryColumns.find((item) => item.id === widget)?.value?.find((item) => item.column === "sparkline")
        ?.hidden === false;
  }

  componentWillUnmount() {
    const { widget, setViewportSymbols } = this.props;
    setViewportSymbols({
      widget,
      symbols: [],
    });
    this.viewportTrackingTimerId && clearInterval(this.viewportTrackingTimerId);
    this.viewportTrackingTimerId = null;

    window.removeEventListener("resize", this.handleWindowResize);

    this.handleWindowResize.cancel();
    this.sparklineAutoLoader.cancel();
    this.onCheckViewportSymbols.cancel();
  }

  componentDidUpdate(prevProps) {
    const discoveryTimeframeChanged = !_.isEqual(
      this.props.discovery.discoveryTimeframe,
      prevProps.discovery.discoveryTimeframe
    );

    if (this.hasSparklines) {
      let updateSparklines = false;
      //If either data or timeframe changed, update sparklines
      updateSparklines = updateSparklines || discoveryTimeframeChanged;

      // If data changed, and last update was more than 5 minutes ago
      updateSparklines =
        updateSparklines ||
        (Date.now() > this.state.sparklineExpiryTime + DISCOVERY_SPARKLINE_UPDATE_INTERVAL &&
          !_.isEqual(this.props.discoveryData, prevProps.discoveryData));

      // If rendered symbols changed (due to sorting, filter...)
      updateSparklines =
        updateSparklines || renderedSymbolsChanged.bind(this)(this.props.discoveryData, prevProps.discoveryData);

      if (updateSparklines) {
        this.setState({ sparklineExpiryTime: Date.now() }, () => {
          const { startIndex, stopIndex } = this.lastRenderedRows;
          this.loadMoreSparklines({
            startIndex,
            stopIndex: Math.min(stopIndex + 2, this.props.discoveryData.length - 1),
          });
        });
      }
    }

    function renderedSymbolsChanged(data, prevData) {
      const lastRenderedSymbols = prevData
        .slice(this.lastRenderedRows.startIndex, this.lastRenderedRows.stopIndex + 1)
        .map((d) => d.symbol);
      const currentRenderedSymbols = data
        .slice(this.lastRenderedRows.startIndex, this.lastRenderedRows.stopIndex + 1)
        .map((d) => d.symbol);

      return !_.isEqual(lastRenderedSymbols, currentRenderedSymbols);
    }
  }

  handleWindowResizeFunc() {
    this.setState({
      winResizeRerender: this.state.winResizeRerender + 1,
    });
  }

  onViewportChanged(range) {
    const { /* overscanStartIndex, overscanStopIndex , */ startIndex, stopIndex } = range;
    this.viewportStartIndex = startIndex;
    this.viewportStopIndex = stopIndex;

    this.onCheckViewportSymbols();
  }

  onCheckViewportSymbols = _.debounce(() => {
    const { discoveryData, widget, setViewportSymbols, onSymbolsAppear } = this.props;
    const symbols = [];
    for (let i = this.viewportStartIndex; i <= this.viewportStopIndex && i < discoveryData?.length; i++) {
      symbols.push(discoveryData[i].symbol);
    }

    const differences = _.difference(symbols, this.visibleSymbols);
    if (differences.length > 0 || symbols.length !== this.visibleSymbols.length) {
      setViewportSymbols({
        widget,
        symbols,
      });
    }
    this.visibleSymbols = symbols;

    onSymbolsAppear(differences);
  }, 1500);

  getDiscoveryWidgetTimeframe() {
    const {
      discovery: { discoveryTimeframe },
      widget,
    } = this.props;

    return discoveryTimeframe[widget];
  }

  loadMoreSparklines({ startIndex: loaderStartIndex, stopIndex: loaderStopIndex }) {
    if (!this.hasSparklines) return;

    const { discoveryData } = this.props;

    let { startIndex, stopIndex } = this.lastRenderedRows;
    startIndex = startIndex ?? loaderStartIndex;
    stopIndex = stopIndex + 2 ?? loaderStopIndex;

    const tf = TIMEFRAME_TRANSFORM_MAP[this.getDiscoveryWidgetTimeframe()];

    const updates = [];
    for (
      let i = Math.min(startIndex, discoveryData.length - 1);
      i <= Math.min(stopIndex, discoveryData.length - 1);
      i++
    ) {
      if (!discoveryData[i]) break;
      const { symbol } = discoveryData[i];
      if (!(this.sparklineData[symbol + tf]?.time > this.state.sparklineExpiryTime)) {
        updates.push(this.fetchSparklineData(i));
      }
    }
    return Promise.allSettled(updates);
  }

  sparklineAutoLoader = _.debounce(this.loadMoreSparklines.bind(this), 500, {
    leading: true,
  });

  _round = (value, decimals) => {
    let res = parseFloat(value).toFixed(decimals);
    if (res == value) {
      res = value;
    }
    return res;
  };

  _renderCell(symbol, data, type, decimals) {
    let roundedValue;
    if (isNaN(data)) {
      roundedValue = "__";
    } else {
      if (type === "%") {
        roundedValue = this._round(data, Math.abs(data) > 10 ? 0 : decimals);
      } else {
        roundedValue = this._round(data, decimals);
      }
      if (roundedValue > 9999 && type === "%") {
        roundedValue = `> 9999${type}`;
      } else {
        roundedValue = data > 0 ? `+${roundedValue}${type}` : `${roundedValue < 0 ? `${roundedValue}${type}` : "-"}`;
      }
    }
    return (
      <div
        style={{
          color: data > 0 ? "#00d25b" : data < 0 ? "#fc424a" : "#9B9B9C",
        }}
      >
        {roundedValue}
      </div>
    );
  }

  _renderCellUVol(symbol, data, type, decimals) {
    const roundedValue = isNaN(data) ? "__" : this._round(data, decimals);
    let toShow = "";
    if (isNaN(data)) {
      toShow = "__";
    } else {
      if (roundedValue > 0) {
        if (roundedValue >= 1000) {
          toShow = `>9999${type}`;
        } else {
          toShow = `+${numAbbr.abbreviate(roundedValue, 2)}${type}`;
        }
      } else {
        toShow = `-`;
      }
    }

    return <div style={{ color: "#9B9B9C" }}>{toShow}</div>;
  }

  _renderCellSqueeze(symbol, data) {
    return data == 0 ? (
      <span className="badge-toggle-indicator active" style={{ width: "10px", height: "10px" }}></span>
    ) : (
      <span style={{ color: "#9B9B9C" }}>{data}</span>
    );
  }

  _renderCellTrend(symbol, rowData, columnData) {
    const trend = this.getCellData(rowData, "trend", columnData);
    if (!Array.isArray(trend) || trend[0] === null) {
      return null;
    }
    return (
      <div className={`trend-wrapper ${trend[0] ? "trending" : ""}`}>
        <div
          className="triangle"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            this.handleTrendClick(e, rowData, columnData);
          }}
          onContextMenu={(e) => {
            this.handleTrendClick(e, rowData, columnData);
          }}
        ></div>
      </div>
    );
  }

  _rowClassName({ index }) {
    if (index < 0) {
      return "row-color-grey";
    } else {
      return index % 2 === 0 ? "row-color-grey" : "row-color-black";
    }
  }

  handleScroll = ({ target: { scrollTop } }) => {
    this.setState({ scrollTop });
  };

  updateSparklineData = (symbol, tf, data, distValue) => {
    if (Date.now() < this.sparklineData[symbol + tf]?.time + DISCOVERY_SPARKLINE_UPDATE_INTERVAL) {
      return;
    }

    let oldDistValue;
    if (!this.sparklineData[symbol + tf]?.oldDistValue) {
      oldDistValue = this.sparklineData[symbol + tf]?.distValue ?? distValue;
    } else if (this.sparklineData[symbol + tf]?.distValue !== distValue) {
      oldDistValue = this.sparklineData[symbol + tf]?.distValue;
    } else {
      oldDistValue = this.sparklineData[symbol + tf]?.oldDistValue;
    }

    this.sparklineData[symbol + tf] = {
      value: data,
      oldValue: this.sparklineData[symbol + tf]?.value,
      distValue: distValue,
      oldDistValue,
      time: Date.now(),
    };
    this.setState({ sparklineUpdated: this.state.sparklineUpdated + 1 });
  };

  fetchSparklineData = async (rowIndex) => {
    const { discoveryData } = this.props;
    const rowData = discoveryData[rowIndex];
    const { symbol } = rowData;
    const distValue = rowData.dollar_dist ?? rowData.price_dist;

    const tf = TIMEFRAME_TRANSFORM_MAP[this.getDiscoveryWidgetTimeframe()];

    try {
      let data = await API.getHistoricalPrice(symbol, tf);
      data = data.data.map((dt) => dt.val);
      this.updateSparklineData(symbol, tf, data, distValue);
    } catch {}
  };

  handleMoneyFlowClick = async (e, rowData, columnData) => {
    e.preventDefault();

    const { setMoneyFlowData, widget } = this.props;
    const { symbol } = rowData;
    const moneyflow_dist = this.getCellData(rowData, "moneyflow_dist", columnData);

    const tf = TIMEFRAME_TRANSFORM_MAP[columnData?.timeframe || this.getDiscoveryWidgetTimeframe()];
    setMoneyFlowData({
      widget,
      symbol,
      dist: moneyflow_dist,
      loading: true,
      tf,
      data: [],
      err: "",
    });

    contextMenu.show({
      id: `${MONEYFLOW_CONTEXT_MENU_ID}-${widget}`,
      event: e,
    });

    try {
      const data = await API.getMoneyFlowData(symbol, tf);
      if (!data || !Array.isArray(data) || data.length === 0) {
        throw "Empty data returned.";
      }
      setMoneyFlowData({
        widget,
        loading: false,
        data: data,
        err: "",
      });
    } catch (e) {
      console.log(e);
      setMoneyFlowData({
        widget,
        loading: false,
        data: [],
        err: "Data unavailable.",
      });
    }
  };

  handleVolumeClick = async (e, rowData, columnData) => {
    e.preventDefault();

    const { setVolumeData, widget } = this.props;
    const { symbol } = rowData;
    const volume_dist = this.getCellData(rowData, "volume_dist", columnData);

    const tf = TIMEFRAME_TRANSFORM_MAP[columnData?.timeframe || this.getDiscoveryWidgetTimeframe()];
    setVolumeData({
      widget,
      symbol,
      dist: volume_dist,
      loading: true,
      tf,
      data: [],
      err: "",
    });

    contextMenu.show({
      id: `${VOLUME_CONTEXT_MENU_ID}-${widget}`,
      event: e,
    });

    try {
      const data = await API.getVolumeData(symbol, tf);
      if (!data || !Array.isArray(data) || data.length === 0) {
        throw "Empty data returned.";
      }
      setVolumeData({
        widget,
        loading: false,
        data: data,
        err: "",
      });
    } catch (e) {
      console.log(e);
      setVolumeData({
        widget,
        loading: false,
        data: [],
        err: "Data unavailable.",
      });
    }
  };

  handleTradeCountClick = async (e, rowData, columnData) => {
    e.preventDefault();

    const { setTradeCountData, widget } = this.props;
    const { symbol } = rowData;

    const tf = TIMEFRAME_TRANSFORM_MAP[columnData?.timeframe || this.getDiscoveryWidgetTimeframe()];
    setTradeCountData({
      widget,
      symbol,
      loading: true,
      tf,
      data: [],
      err: "",
    });

    contextMenu.show({
      id: `${TRADE_COUNT_CONTEXT_MENU_ID}-${widget}`,
      event: e,
    });

    try {
      const data = await API.getTradeCountData(symbol, tf);
      if (!data || !Array.isArray(data) || data.length === 0) {
        throw "Empty data returned.";
      }
      setTradeCountData({
        widget,
        loading: false,
        data: data,
        err: "",
      });
    } catch (e) {
      console.log(e);
      setTradeCountData({
        widget,
        loading: false,
        data: [],
        err: "Data unavailable.",
      });
    }
  };

  handleTrendClick = async (e, rowData, columnData) => {
    e.preventDefault();

    const { setTrendData, widget } = this.props;
    const { symbol } = rowData;
    const trend = this.getCellData(rowData, "trend", columnData);

    setTrendData({
      widget,
      symbol,
      trend: trend[0],
      pricePoint: trend[1],
      ROI: 0,
    });

    contextMenu.show({
      id: `${TREND_CONTEXT_MENU_ID}-${widget}`,
      event: e,
    });
  };

  handleAlertClick = async (e, rowData) => {
    e.preventDefault();
    e.stopPropagation();

    const { widget } = this.props;

    contextMenu.show({
      id: `${ALERT_CONTEXT_MENU_ID}-${widget}`,
      event: e,
    });
    this.props.onAlertTrigger(widget, rowData.symbol);
  };

  updateChartWidget({ symbol, color, tf, source }) {
    const detail = {};
    symbol && (detail.symbol = symbol);
    color && (detail.color = color);
    tf && (detail.tf = tf);
    source && (detail.source = source);

    const event = new CustomEvent("chartWidgetUpdate", {
      detail,
    });
    window.dispatchEvent(event);
    window.opener && window.opener.dispatchEvent(event);
  }

  getHeaderData(discovery_config) {
    const columnsInfo = {
      price_dist: {
        label: "Last",
        width: 65,
        className: `discovery-tbl-header`,
      },
      volume: {
        label: "Volume",
        width: 73,
        className: `discovery-tbl-header`,
      },
      moneyflow: {
        label: "Money Flow",
        width: 100,
        className: `discovery-tbl-header`,
      },
      marketCap: {
        label: "Market Cap",
        width: 100,
        className: `discovery-tbl-header`,
      },
      float: {
        label: "Float",
        width: 64,
        className: `discovery-tbl-header`,
      },
      momentum: {
        label: "Momentum",
        width: 88,
        className: `discovery-tbl-header`,
      },
      tradeCount: {
        label: "Trades",
        width: 62,
        className: `discovery-tbl-header`,
      },
      uVol: {
        label: "uVol",
        width: 62,
        className: `discovery-tbl-header`,
      },
      vWapDist: {
        label: "VWAP Dist",
        width: 80,
        className: `discovery-tbl-header`,
      },
      vWapSlope: {
        label: "Vector",
        width: 80,
        className: `discovery-tbl-header`,
      },
      short_ratio: {
        label: "Short Ratio",
        width: 87,
        className: `discovery-tbl-header`,
      },
      sparkline: {
        label: "Sparkline",
        width: 85,
        className: `discovery-tbl-header`,
      },
      squeeze: {
        label: "Squeeze",
        width: !this.props.isSmallScreen ? 96 : 68,
        className: `discovery-tbl-header`,
      },
      atr: {
        label: "ATR",
        width: 65,
        className: `discovery-tbl-header pl-2`,
      },
      halt: {
        label: "Halt",
        width: 56,
        className: `discovery-tbl-header pl-2`,
      },
      trend: {
        label: "Trend",
        width: 40,
        className: `discovery-tbl-header`,
      },
      gap: {
        label: "Gap",
        width: 65,
        className: `discovery-tbl-header`,
      },
      rs: {
        label: "RelStr",
        width: 80,
        className: `discovery-tbl-header`,
      },
      alert: {
        label: "Actions",
        width: 65,
        className: `discovery-tbl-header`,
        style: { overflowX: "auto" },
      },
    };

    const { user } = this.props;
    const plan = getPlanSubscriptionStatus({ user });

    let columns = [
      {
        columnType: "symbol",
        label: "Symbol",
        width: 80,
        className: "discovery-tbl-header",
        style: {
          fontWeight: 600,
          paddingLeft: 10,
        },
      },
    ];

    let priority = 1;
    for (const item of discovery_config) {
      if (!item.hidden && isColumnAccessible(item, plan)) {
        let columnType = item.column === "actions" ? "alert" : item.column;
        const columnInfo = _.cloneDeep(columnsInfo[columnType]);
        columnInfo.columnType = columnType;
        columnInfo.className += ` column-priority-${priority++}`;
        if (item.custom) {
          columnInfo.custom = true;
          columnInfo.timeframe = item.timeframe;
        }
        columnInfo.columnSetting = item;
        columns.push(columnInfo);
      }
    }

    return columns;
  }

  getCellData(rowData, columnType, columnData) {
    let field = columnType;
    if (
      columnData?.custom &&
      columnData?.timeframe &&
      !DISCOVERY_TIMEFRAME_INDEPENDENT_DATA_FIELDS.includes(columnType)
    ) {
      field = encodeDiscoveryFieldForTimeframe(columnType, columnData.timeframe);
    }
    return rowData[field];
  }

  cellRenderer({ rowIndex, columnIndex, style, key, parent }, cellRendererArgs) {
    if (rowIndex === 0) {
      return this.headerCellRenderer(
        {
          rowIndex,
          columnIndex,
          style,
          key,
          parent,
        },
        cellRendererArgs
      );
    } else {
      rowIndex -= 1;
      return this.dataCellRenderer({ rowIndex, columnIndex, style, key, parent }, cellRendererArgs);
    }
  }

  customColumnMarkRenderer(columnData) {
    const { timeframe } = columnData;
    return (
      <div className={styleClasses["custom-column-mark"]}>
        <DiscoveryLockedFieldMark timeframe={timeframe} palette="orange" />
      </div>
    );
  }

  headerCellRenderer({ rowIndex, columnIndex, style, key, parent }, cellRendererArgs) {
    const { _sort, sortBy, sortDirection } = this.props;

    const { visibleHeaderData } = cellRendererArgs;

    const columnData = visibleHeaderData[columnIndex];
    const columnType = columnData.columnType;
    const columnKey = columnData.custom
      ? encodeDiscoveryFieldForTimeframe(columnType, columnData.timeframe)
      : columnType;

    if (columnType === "squeeze") {
      const sort_direction_label = SQUEEZE_SORT_LABEL[sortDirection];
      return (
        <span
          className={
            `squeeze-header ReactVirtualized__Table__headerColumn ${sortBy === columnKey ? "has-sort" : ""}` +
            " " +
            (rowIndex % 2 === 0 ? "evenRow" : "oddRow")
          }
          style={{
            ...style,
            margin: 0,
            ...(columnIndex === 0 ? { paddingLeft: "10px" } : {}),
            display: "grid",
            gridAutoFlow: "column",
            paddingRight: "5px",
            justifyContent: "start",
            alignItems: "center",
          }}
          onClick={() => {
            _sort({ sortBy: columnKey });
          }}
          key={key}
        >
          {columnData.custom && this.customColumnMarkRenderer(columnData)}
          {<span className={`squeeze-label`} style={{}}></span>}
          {sortBy === columnKey && (
            <div
              className={`squeeze-sort squeeze-sort-${sort_direction_label.toLowerCase()}`}
              style={{
                height: "12px",
                display: "grid",
                placeContent: "center",
              }}
            >
              <span style={{ lineHeight: 0 }}>{sort_direction_label}</span>
            </div>
          )}
        </span>
      );
    } else {
      return (
        <div
          style={{
            ...style,
            flex: "0 1 200px",
            alignItems: "center",
            margin: 0,
            ...(columnIndex === 0 ? { paddingLeft: "10px" } : {}),
          }}
          className={
            "ReactVirtualized__Table__headerColumn cursor-pointer " + (rowIndex % 2 === 0 ? "evenRow" : "oddRow")
          }
          key={key}
          onClick={() => {
            _sort({ sortBy: columnKey });
          }}
        >
          {columnData.custom && this.customColumnMarkRenderer(columnData)}
          <span className="ReactVirtualized__Table__headerTruncatedText" title={visibleHeaderData[columnIndex].label}>
            {visibleHeaderData[columnIndex].label}
          </span>
          {sortBy === columnKey && (
            <svg
              className={
                "ReactVirtualized__Table__sortableHeaderIcon ReactVirtualized__Table__sortableHeaderIcon--" +
                sortDirection
              }
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path d={sortDirection === "ASC" ? "M7 14l5-5 5 5z" : "M7 10l5 5 5-5z"}></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          )}
        </div>
      );
    }
  }

  dataCellRenderer({ rowIndex, columnIndex, style, key, parent }, cellRendererArgs) {
    const { discoveryData, widget } = this.props;

    const filterFadeoutTime =
      this.props.discovery.filterFadeoutTime?.[widget] || DISCOVERY_DEFAULT_FILTEROUT_FADEOUT_TIME;

    const { visibleHeaderData, lastDistDisplayOption, news_config, tf } = cellRendererArgs;

    const data = discoveryData;

    const rowData = data[rowIndex];
    const columnData = visibleHeaderData[columnIndex];
    const columnType = columnData.columnType;
    const cellData = this.getCellData(rowData, columnType, columnData);

    const displayOption = columnData.columnSetting?.display || DISCOVERY_DISPLAY_PERCENT_CHANGE;

    const classes = {
      volume: "column-volume",
      moneyflow: "column-moneyflow",
      momentum: "column-momentum",
      tradeCount: "column-tradecount",
      short_ratio: "column-short-ratio",
      squeeze: "column-squeeze",
    };

    const timeNow = Date.now();

    return (
      <div
        aria-colindex="16"
        className={
          "ReactVirtualized__Table__rowColumn " +
          [rowIndex % 2 === 0 ? "evenRow" : "oddRow", classes[columnType] ?? ""].join(" ")
        }
        role="gridcell"
        style={{
          ...style,
          margin: 0,
          ...(columnIndex === 0 ? { paddingLeft: "10px" } : {}),
          display: "flex",
          alignItems: "center",
        }}
        key={columnIndex === 0 && rowData.filterFader_addedTime ? timeNow - rowData.filterFader_addedTime : key}
      >
        <div
          style={{
            "--filterFadeoutTime": filterFadeoutTime + "ms",
            "--filterFadeoutDelay": rowData.filterFader_addedTime - timeNow + "ms",
          }}
        >
          {rowData.filterFader_addedTime &&
            columnIndex === 0 &&
            timeNow - rowData.filterFader_addedTime < filterFadeoutTime && (
              <div className={styleClasses.filterfader}></div>
            )}
          {/* {columnIndex === 0 && rowData.filterFader_addedTime && <div>🟡</div>}
          {columnIndex === 0 &&
            rowData.filterFader_addedTime &&
            (timeNow - rowData.filterFader_addedTime < filterFadeoutTime ? (
              <div>🟢{filterFadeoutTime + rowData.filterFader_addedTime - timeNow + " "} </div>
            ) : (
              <div>🔴</div>
            ))} */}
        </div>

        {CellContent.bind(this)()}
      </div>
    );

    function CellContent() {
      switch (columnType) {
        case "symbol":
          return (() => {
            let isNews = false;
            const news = this.getCellData(rowData, "news", columnData);
            if (!!news) {
              const duration = moment.duration(moment().diff(moment(news)));
              let diff_minutes = parseInt(duration.asMinutes());
              if (diff_minutes <= 60 * news_config.recency) {
                isNews = true;
              }
            }

            let fontSizeAdjust = {};
            let symbolSize = cellData.length - (cellData.includes(".") ? 1 : 0);
            if (symbolSize >= 4 && (this.getCellData(rowData, "trending", columnData) || isNews)) {
              fontSizeAdjust = { fontSize: "12px" };
            }
            return (
              <div className="d-flex align-items-center" style={{ ...fontSizeAdjust }}>
                <Symbol symbol={cellData} showOptions={true} />
                <div className="d-flex flex-column">
                  {this.getCellData(rowData, "trending", columnData) && (
                    <img
                      className="stockwits"
                      src={require("../../assets/images/dashboard/stock-tweets.svg")}
                      style={{ position: "static" }}
                      alt="stock-tweets"
                    />
                  )}
                  {isNews && (
                    <img
                      className="stockwits"
                      src={require("../../assets/images/dashboard/news-icon-color.svg")}
                      style={{
                        position: "static",
                        marginTop: this.getCellData(rowData, "trending", columnData) ? "2px" : 0,
                      }}
                      alt="news-icon-color"
                    />
                  )}
                </div>
              </div>
            );
          })();
        case "price_dist":
          return (() => {
            let last = Number(this.getCellData(rowData, "last", columnData));
            last = isNaN(last) ? "__" : last.toFixed(2);
            const distValue =
              displayOption === DISCOVERY_DISPLAY_PERCENT_CHANGE
                ? this.getCellData(rowData, "price_dist", columnData)
                : this.getCellData(rowData, "dollar_dist", columnData);
            let roundedValue = distValue;
            if (isNaN(distValue)) {
              roundedValue = "-";
            } else {
              if (displayOption === DISCOVERY_DISPLAY_PERCENT_CHANGE) {
                roundedValue = this._round(distValue, Math.abs(distValue) > 10 ? 0 : 2);
                if (distValue > 9999) {
                  roundedValue = "> 9999%";
                } else {
                  roundedValue = roundedValue > 0 ? `+${roundedValue}%` : `${roundedValue}%`;
                }
              } else {
                roundedValue = roundedValue > 0 ? `+${roundedValue}` : `${roundedValue}`;
              }
            }
            return (
              <div>
                <div style={{ fontWeight: 600 }}>{last}</div>
                <small
                  className={
                    "price-dist " +
                    (distValue == 0 || isNaN(distValue) ? "" : distValue > 0 ? "text-success" : "text-danger")
                  }
                >
                  {roundedValue}
                </small>
              </div>
            );
          })();
        case "volume":
          return (() => (
            <span
              onClick={(e) => {
                this.handleVolumeClick(e, rowData, columnData);
              }}
              onContextMenu={(e) => {
                this.handleVolumeClick(e, rowData, columnData);
              }}
              style={{ color: "#9B9B9C", cursor: "pointer" }}
            >
              {!isNaN(cellData) ? numAbbr.abbreviate(cellData, 2) : "-"}
            </span>
          ))();
        case "moneyflow":
          return (() => (
            <span
              onClick={(e) => {
                this.handleMoneyFlowClick(e, rowData, columnData);
              }}
              onContextMenu={(e) => {
                this.handleMoneyFlowClick(e, rowData, columnData);
              }}
              style={{ color: "#9B9B9C", cursor: "pointer" }}
            >
              {!isNaN(cellData) ? numAbbrExt.abbreviate(cellData, 2) : cellData}
            </span>
          ))();
        case "marketCap":
          return (() => (
            <div style={{ color: "#9B9B9C" }}>{cellData ? numAbbrExt2.abbreviate(cellData, 2) : "-"}</div>
          ))();
        case "float":
          return (() => (
            <div style={{ color: "#9B9B9C" }}>{cellData ? numAbbrExt2.abbreviate(cellData, 2) : "-"}</div>
          ))();
        case "momentum":
          return (() => this._renderCell(rowData.symbol, cellData, "", ""))();
        case "tradeCount":
          return (() => (
            <span
              onClick={(e) => {
                this.handleTradeCountClick(e, rowData, columnData);
              }}
              onContextMenu={(e) => {
                this.handleTradeCountClick(e, rowData, columnData);
              }}
              style={{ color: "#9B9B9C", cursor: "pointer" }}
            >
              {cellData && (!isNaN(cellData) && cellData > 0 ? numAbbr.abbreviate(cellData, 2) : "-")}
            </span>
          ))();
        case "uVol":
          return (() => this._renderCellUVol(rowData.symbol, cellData, "%", "0"))();
        case "vWapDist":
          return (() => this._renderCell(rowData.symbol, cellData, "%", "2"))();
        case "vWapSlope":
          return (() => this._renderCell(rowData.symbol, cellData, "", "2"))();
        case "short_ratio":
          return (() => <div style={{ color: "#9B9B9C" }}>{cellData ? numAbbr.abbreviate(cellData, 2) : "-"}</div>)();
        case "squeeze":
          return (() => this._renderCellSqueeze(rowData.symbol, cellData))();
        case "trend":
          return (() => this._renderCellTrend(rowData.symbol, rowData, columnData))();
        case "atr":
          return (() => {
            let value =
              displayOption === DISCOVERY_DISPLAY_PERCENT_CHANGE
                ? this.getCellData(rowData, "atr", columnData)
                : this.getCellData(rowData, "atr_dollar_dist", columnData);
            value = numAbbrExt2.abbreviate(value, 2);
            return (
              <div style={{ color: "#9B9B9C" }}>
                {value ? `${value}${displayOption === DISCOVERY_DISPLAY_PERCENT_CHANGE ? "%" : ""}` : "-"}
              </div>
            );
          })();
        case "halt":
          return (() => {
            return (
              <div className="discovery-halt-luld" style={{ color: "#9B9B9C" }}>
                {rowData["halt"] && <i className="cell-halt fa fa-lock ml-3"></i>}
                {!rowData["halt"] && rowData["luld"] && isRegularMarketOpen() && (
                  <LULD up={rowData["luld"][0]} down={rowData["luld"][1]} />
                )}
                {!rowData["halt"] && (!rowData["luld"] || !isRegularMarketOpen()) && <span>-</span>}
              </div>
            );
          })();
        case "gap":
          return (() => {
            const value =
              displayOption === DISCOVERY_DISPLAY_PERCENT_CHANGE
                ? this.getCellData(rowData, "gap_percent_dist", columnData)
                : this.getCellData(rowData, "gap", columnData);
            return (
              <div style={{ color: "#9B9B9C" }}>
                {value ? `${value}${displayOption === DISCOVERY_DISPLAY_PERCENT_CHANGE ? "%" : ""}` : "-"}
              </div>
            );
          })();
        case "rs":
          return (() => this._renderCell(rowData.symbol, cellData, "%", "1"))();
        case "alert":
          return (() => (
            <div className="action-column">
              <span
                className="mdi mdi-bell text-white popover-icon action-button-margin"
                onClick={(e) => this.handleAlertClick(e, rowData)}
                onContextMenu={(e) => this.handleAlertClick(e, rowData)}
              />
              <i
                className={`${
                  this.props.checkIsFavorite(cellData)
                    ? "mdi mdi-star quote-star popover-icon"
                    : "mdi mdi-star text-white popover-icon"
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => this.props.onSetSymbolFav(rowData.symbol)}
              />
            </div>
          ))();
        case "sparkline":
          const distValue =
            lastDistDisplayOption === DISCOVERY_DISPLAY_PERCENT_CHANGE
              ? this.getCellData(rowData, "price_dist", columnData)
              : this.getCellData(rowData, "dollar_dist", columnData);

          return (
            <Sparkline
              {...{
                symbol: rowData.symbol,
                tf,
                distValue,
                sparklineData: this.sparklineData,
                widget: this.props.widget,
                updateChartWidget: this.updateChartWidget,
              }}
            />
          );

        default:
          return <></>;
      }
    }
  }

  render() {
    // console.log("UPDATD TABLE" + Math.random());
    const { discoveryData, widget } = this.props;
    let {
      config: { news: news_config, discovery: discovery_config },
    } = this.props;
    if (!news_config) {
      news_config = DEFAULT_NEWS_CONFIG;
    }
    if (!discovery_config) {
      discovery_config = DEFAULT_DISCOVERY_SETTING_LIST;
    }
    discovery_config = discovery_config.find((item) => item.id === widget);
    discovery_config = discovery_config?.value || DEFAULT_DISCOVERY_COLUMNS;

    let lastDistDisplayOption;
    for (const item of discovery_config) {
      if (item.column === "price_dist" && !item.custom) {
        lastDistDisplayOption = item.display || DISCOVERY_DISPLAY_PERCENT_CHANGE;
      }
    }

    const headerData = this.getHeaderData(discovery_config);
    const visibleHeaderData = headerData.filter((header) => !header.hidden);

    const tf = TIMEFRAME_TRANSFORM_MAP[this.getDiscoveryWidgetTimeframe()];

    const cellRendererArgs = {
      headerData,
      visibleHeaderData,
      lastDistDisplayOption,
      news_config,
      tf,
    };

    return (
      <div className="container h-100 px-0 px-sm-3" style={{ position: "absolute" }}>
        {/* <ContextMenuTrigger
          id={"discovery-alert-context-menu"}
          ref={(c) => (alertContextTrigger = c)}
        >
        </ContextMenuTrigger> */}
        <InfiniteLoader
          isRowLoaded={({ index }) =>
            this.sparklineData[discoveryData[index].symbol + tf]?.time > this.state.sparklineExpiryTime
          }
          loadMoreRows={this.sparklineAutoLoader}
          rowCount={discoveryData.length}
        >
          {({ onRowsRendered, registerChild }) => (
            <div style={{ width: "100%", height: "100%" }} className={styleClasses.multigrid}>
              <AutoSizer style={{ width: "100%", height: "100%" }}>
                {({ width, height }) => (
                  <>
                    <MultiGrid
                      ref={registerChild}
                      key={this.state.winResizeRerender + width}
                      width={width}
                      height={height}
                      rowHeight={65}
                      // autoHeight
                      rowCount={discoveryData.length + 1}
                      columnCount={visibleHeaderData.length}
                      cellRenderer={(data) => this.cellRenderer(data, cellRendererArgs)}
                      columnWidth={({ index }) => {
                        const headers = visibleHeaderData;
                        return Math.round(Math.max(headers[index].width, width / headers.length));
                      }}
                      fixedRowCount={1}
                      fixedColumnCount={1}
                      style={{ fontSize: 14 }}
                      onSectionRendered={(params) => {
                        params = {
                          ...params,
                          overscanStartIndex: params.rowOverscanStartIndex,
                          overscanStopIndex: params.rowOverscanStopIndex,
                          startIndex: params.rowStartIndex,
                          stopIndex: params.rowStopIndex,
                        };
                        this.lastRenderedRows = {
                          startIndex: params.startIndex,
                          stopIndex: params.stopIndex,
                        };
                        onRowsRendered(params);
                        this.onViewportChanged(params);
                      }}
                      overscanRowCount={2}
                    />
                  </>
                )}
              </AutoSizer>
            </div>
          )}
        </InfiniteLoader>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setMoneyFlowData: DiscoveryActions.setMoneyFlowData,
  setVolumeData: DiscoveryActions.setVolumeData,
  setTradeCountData: DiscoveryActions.setTradeCountData,
  setTrendData: DiscoveryActions.setTrendData,
  setViewportSymbols: DiscoveryActions.setViewportSymbols,
};

const mapStateToProps = (state, props) => ({
  config: state.config,
  discovery: state.discovery,
  user: state.auth.user,
  isPro:
    isActiveSubscription(state.auth.user.subscription) &&
    (isPro(state.auth.user.subscription.plan) || isProNew(state.auth.user.subscription.plan)),
  isProOld: isActiveSubscription(state.auth.user.subscription) && isPro(state.auth.user.subscription.plan),
  isProPlus: isActiveSubscription(state.auth.user.subscription) && isProPlusNew(state.auth.user.subscription.plan),
  chart: state.chart,
});

export default withScreenSizes(connect(mapStateToProps, mapDispatchToProps)(memo(DiscoveryTable)));
